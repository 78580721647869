<template>
  <Page class="page-shops page-list-shops">
    <template #title>Shops</template>

    <template #default>
      <template v-if="shops && shops.length > 0">
        <AwlTable :heading="tableHeadings" :rows="usersCleaned" striped>
          <template #column-key-owner="{ row }">
            <router-link v-if="row.user" :to="{ name: 'users.show', params: { id: row.user.id } }">{{ row.user.firstName }} {{ row.user.lastName }}</router-link>
            <span v-else>-</span>
          </template>

          <template #actions-content="{ row }">
            <ActionShow :to="{ name: 'shops.show', params: { id: row.id } }" />
          </template>
        </AwlTable>

        <div class="row justify-content-end align-shops-center">
          <div class="col-auto text-right">
            <p>
              Risultati da mostrare
              <PerPageSelector class="d-inline w-auto" v-model="perPage" :options="perPageOptions" @input="fetchResults(1)" />
            </p>
          </div>
        </div>

        <AwlPagination :value="page" :totalPages="totalPages" @page="goToPage" />
      </template>
      <Alert v-else status="info">Nessuno shop presente</Alert>
    </template>
  </Page>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import pageableMixin from '@/libs/Pagination/mixins/pageable.js';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal.js';
import rolesMixin from '@/mixins/roles.js';

import Page from '@/views/components/Private/Page.vue';
import AwlPagination from '@/libs/Pagination/components/AwlPagination.vue';
import PerPageSelector from '@/libs/Pagination/components/PerPageSelector.vue';
import AwlTable from '@/libs/Table/components/AwlTable.vue';
import ActionShow from '@/libs/Table/components/Actions/ActionShow.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  $api.listShops()
    .then(res => next(vm => {
      vm.shops = res?.data?.shops || [];
      vm.setMetadata(res?.data);
    }))
    .catch(() => next(from))
  ;
}

export default {
  mixins: [pageableMixin, confirmWithModalMixin, rolesMixin],
  components: {
    Page,
    AwlPagination,
    PerPageSelector,
    AwlTable,
    ActionShow,
    Alert: () => import('@/libs/Feedback/components/Alert.vue'),
  },
  data: () => ({ shops: [] }),
  computed: {
    tableHeadings () {
      return [
        { key: 'name', label: 'Nome' },
        { key: 'language', label: 'Lingua' },
        { key: 'statusName', label: 'Stato' },
        { key: 'owner', label: 'Proprietario' },
      ];
    },
    usersCleaned () {
      return this.shops.map(s => {
        if (s.enabled) {
          s.statusName = 'Attivo';
        } else {
          s.statusName = 'Disattivato';
        }

        return s;
      });
    },
  },
  methods: {
    fetchResults (page = this.page) {
      return $api.listShops(page, this.perPage)
        .then(res => {
          this.shops = res?.data?.shops || [];
          this.setMetadata(res?.data);
        })
        .catch(() => this.$log.error)
      ;
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
